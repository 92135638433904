#popContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  /*height: 60%; TODO Error iOS*/
  min-width: 340px;
  outline: none;
  /*-webkit-overflow-scrolling: touch;*/
}

#botClose {
  background-color: #dc0e5a;
  border: 3px solid #e456b3;
  border-radius: 5px;
  padding: 3px 9px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white;
  position: relative;
  top: 29px;
  right: -20%;
  cursor: pointer;
}

#brownBackCont {
  width: 200px;
  height: 50px;    
}

#brownBackContInt {
  width: 200px;
  height: 50px;
  overflow: hidden;
  position: relative;
  top: 3px;
  /*-webkit-overflow-scrolling: touch;*/
}


#brownBack {
  width: calc( 100% - 6px );
  height: 200%;
  background-color: #733027;
  position: relative;
  z-index: 1001;
  border: 3px solid #7a423b;
  border-radius: 50%;
  border-bottom: none;  
} 

#brownBack2 {
  display: table;
  max-width: 600px;
  width: 100%;
  height: 80%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#733027+0,662a23+50,4a1f19+100 */
  background: #733027; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%);
  background: -o-linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%);
  background: linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#733027', endColorstr='#4a1f19',GradientType=0 ); /* IE6-9 */
  z-index: 1000;
  position: relative;
  border: 3px solid #7a423b;
  border-radius: 80px;
} 

#ornaments {
  display: flex;
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: calc( 90% - 6px );
  flex-direction: column;
  border: 3px solid #E21A67;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 3px #e21a67, 0px 0px 10px 3px #e21a67;
  z-index: -1;
}

#ornaments > div {
  flex: 1;
  display: flex;
}

#ornament00 {
  flex: 1;  
}

#ornament01 {
  height: 30px;
  flex: 1 1;
  background-color: #702e27;
  position: relative;
  top: -14px;
}

.logoClass {
  width: 149px;
  height: 123px;
  margin: auto;
  position: relative;
  top: -19px;
  z-index: 1001;  
}

#logoPopUp-Registro {
  /*background: url(./assets/background/registro.png) no-repeat;  */
  background-size: 100% 100%;
}

#logoPopUp-Terms {
  /*background: url(./assets/background/terms.png) no-repeat;  */
  background-size: 100% 100%;
}

#logoPopUp-Ingresar {
  /*background: url(./assets/background/ingresar.png) no-repeat;  */
  background-size: 100% 100%;
}

#logoPopUp-Ranking {
  /*background: url(./assets/background/ranking.png) no-repeat;  */
  background-size: 100% 100%;
}

#logoPopUp-Help {
  /*background: url(./assets/background/helpLogo.png) no-repeat;  */
  background-size: 100% 100%;
}

#ornament02 {
  flex: 1;
}

#content {
  padding: 13vh 10% 7vh 10%;
  /*-webkit-overflow-scrolling: touch;*/
}

#contentRegistro: {
  padding: 0 10% 0 10%;
  margin: 10vh 0 10vh 0;
  max-height: 370px;
  overflow-y: scroll;
}

#buttonClose {
  width: 86px;
  height: 85px;
  /*background: url(./assets/background/close-btn.png) no-repeat;*/
  background-size: 100% 100%;
  position: absolute;
  top: -50px;
  right: 25px;
  cursor: pointer;
}

#buttonClose:hover {
  filter: brightness(0.85);  
}

#buttonClose:active {
  filter: brightness(0.7); 
}

#brownBack2NoLogo {
  width: 60%;
  height: 80%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#733027+0,662a23+50,4a1f19+100 */
  background: #733027; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%);
  background: -o-linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%);
  background: linear-gradient(#733027 0%, #662a23 50%, #4a1f19 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#733027', endColorstr='#4a1f19',GradientType=0 ); /* IE6-9 */
  z-index: 1000;
  position: relative;
  border: 3px solid #E21A67;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 3px #e21a67, 0px 0px 10px 3px #e21a67;  
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
} 

#ornamentsNoLogo {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: 3px solid #E21A67;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 3px #e21a67, 0px 0px 10px 3px #e21a67;
  z-index: -1;
}

#contentNoLogo {
  width: 90%;
  padding: 5vh 8vh;
  color: white;
  text-align: center;
  white-space: pre-wrap;
}

#buttonCloseNoLogo {
  width: 86px;
  height: 85px;
  /*background: url(./assets/background/close-btn.png) no-repeat;*/
  background-size: 100% 100%;
  position: relative;
  left: 30%;
  cursor: pointer;
}

#buttonCloseNoLogo:hover {
  filter: brightness(0.85);  
}

#buttonCloseNoLogo:active {
  filter: brightness(0.7); 
}

@media only screen and (max-width: 720px) {

  .logoClass {
    width: 75px;
    height: 66px;
    top: 55px;
  }

  #brownBackContInt {
    display: none;
  }

  #buttonClose {
    position: relative;
    top: 50px;
    right: 0px;
  }

  #botClose {
    top: 30px;
    right: 0px;
  }

  #botClose:hover {
    filter: brightness(0.85);  
  }

  #botClose:active {
    filter: brightness(0.7); 
  }

  #popContainer {
    position: relative;
    top: -25px;      
  }

  #buttonCloseNoLogo {
    position: relative;
    top: 0px;
    left: 0px;
  }

  #brownBack2NoLogo {
    width: 90%;        
  }

  #contentNoLogo {
    padding: 3vh 6vh;
  }
}