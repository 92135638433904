fieldset {
  border-color: #f8e926 !important;
}

#labelOlvido {
  margin-top: 10px; 
}

a {
  margin-left: 5px;
  color: #00b7ff;
  cursor: pointer;
  overflow: visible;
}

#forgot-content {
  display: flex;
  color: white;
  flex-direction: column;    
  align-items: center;
}

