.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}


body {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  background: url("assets/backgroundPremios.png");
  background-size: cover;
  height: 100%;
  margin: 0;
  /*min-width: 600px;*/
  overflow-x: hidden;
  background-position: center center;
  background-repeat: no-repeat;
}

html {
  display: none;
  height: 100%;
  padding: 0;
  margin: 0;
  background: url("assets/grass_floor.jpg");
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-position: center center;
}


.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;

}

.orientation-msg-container {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  background-color: #000;
  top: 0;
}

.orientation-msg-text {
  font-size: 40px;
  font-family: "Arial";
  color: #fff;
  text-align: center;
  width: 80%;

  position: fixed;
  top: 50%;
  -webkit-transform: translate(15%, -50%);
  -moz-transform: translate(15%, -50%);
  -ms-transform: translate(15%, -50%);
  transform: translate(15%, -50%);
}

#root {
  height: 100%;
}

#menu {
  height: 10%;
  position: fixed;
  width: 100%;
  display: none;
}

#header {
  height: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#center {
  height: 70%;
  position: relative;
  display: flex;
  align-items: center;
}

.gameButtons {
  display: flex;
  align-items: center;
  width: 100px;
  background-color: cornflowerblue;
  justify-content: center;
  color: white;
  font-family: roboto;
  border-radius: 10px;
  border: solid 3px darkblue;
  cursor: pointer;
}

#gameButtonsContainer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-around;
}

#footer {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00318c;
  font-size: 0.8em;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

#footer_social {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

#footer_info {
  display: flex;
  flex: 8;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.dot {
  position: relative;
  top: -3px;
}

.footer_info_item {
  margin: 5px;
  color: white;
  text-decoration: none;
  font-family: Titillium Web;

}

.footer_link {
  cursor: pointer;
}

.footer_link:hover {
  text-decoration: underline;
}

.but_social {
  height: 23px;
  width: 23px;
  background-size: cover;
  margin: 5px;
}

.but_social:hover {
  opacity: 0.9;
  cursor: pointer;
}

#social_instagram {
  background-image: url(assets/social_ins.png);
}

#social_fb {
  background-image: url(assets/social_fb.png);
}

#social_twitter {
  background-image: url(assets/social_twitter.png);
}


#logoCrem {
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
}

#logoCremInt {
  /*width: 142px;
  height: 144px;*/
  width: 220px;
  height: 152px;
  position: relative;
  top: 23px;
  /*background: url(./assets/background/crem-helado-logo.png) no-repeat;*/
  background-size: 100% 100%;
}

#logoGame {
  display: flex;
  height: 66px;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /*z-index: -10;*/
}

@media (max-width: 1100px) {
  #logoGame {
    width: 80%;
  }
}

#textAward {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  color: white;
  width: 80%;
  max-width: 820px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
}

@media (max-width: 960px) {
  #logoGame {
    visibility: hidden;
  }
}

#logoGameInt {
  width: 382px;
  height: 66px;
  background: url(assets/top.png) no-repeat;
  background-size: 100% 100%;
}

#buttonLogin {
  position: absolute;
  left: 28px;
  height: 100px;
  z-index: 1;
}

#buttons {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  right: 28px;
}

#circLoader {
  outline: 0px solid transparent;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50%);
}

#game {
  margin: auto;
}

#decoBackTop {
  width: 100%;
  /*background: url(./assets/background/back-1.jpg) repeat-x;
  background-size: 100% 100%;*/
  min-height: 614px;
  position: fixed;
  z-index: 0;
  min-width: 600px;
  height: 100%;
  bottom: 29px;
}

#decoBackTopMobile {
  width: 100%;
  /*background: url(./assets/background/back-1.jpg) repeat-x;*/
  background-size: 100% 100%;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.decoBackGeneral {
  width: 30%;
  position: absolute;
  top: 0;
  height: 100%;
  background-size: contain;
  background-position: bottom;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-repeat: no-repeat;
}

#decoBackTopLeft {
  left: 0;
  background-image: url(assets/left.png);
}

#decoBackTopRight {
  right: 0;
  /*background-image: url(assets/right.png);  */
  background-image: url(assets/logo20.png);
  background-position-y: 96%;
  width: 19%;
}

#decoBackBottom {
  width: 100%;
  z-index: 1000;
  min-width: 600px;
  position: absolute;
  bottom: 300px;
}

#decoBackBottomLeft {
  width: 326px;
  height: 354px;
  position: absolute;
  top: 0;
  left: 0;
  /*background: url(./assets/background/left-3.png) no-repeat;*/
  background-size: 100% 100%;
}

#decoBackBottomRight {
  width: 302px;
  height: 330px;
  position: absolute;
  top: 0;
  right: 0;
  /*background: url(./assets/background/right-3.png) no-repeat;*/
  background-size: 100% 100%;
}

#lateralButtons {
  width: 100%;
  /*z-index: -1;*/
  min-width: 600px;
  position: absolute;
  bottom: 0px;
}

#lateralButtonsLeft {
  width: 100%;
  height: 184px;
  bottom: 0px;
  position: absolute;
  background-color: #fff000;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#textAwards {
  width: 645px;
  height: 53px;
  min-height: 53px;
  background: url(assets/textAwards.png) no-repeat;
  background-size: contain;
}

.botSubir {
  width: 40%;
  display: flex;
  position: relative;
  min-width: 270px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #00318c;
  background: #fff;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  min-height: 36px;
}

.botSubir input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#flechaArriba {
  width: 12px;
  height: 12px;
  border-top: solid 2px white;
  border-right: solid 2px white;
  transform: rotate(-45deg);
  margin-top: 5px;
}

#flechaDerecha {
  width: 7px;
  height: 10px;
  border-top: solid 3px white;
  border-right: solid 3px white;
  transform: rotate(45deg);
  margin-left: 9px;
}

#containerFlecha {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#LoginButton {
  width: 126px;
  height: 231px;
  background-size: auto;
  position: relative;
  background-repeat-y: no-repeat;
  background-repeat: no-repeat;
  background-image: url(assets/bandera_login.png);
  transition: top 0.2s;
  margin-right: 20px;
  padding: 10px;
  padding-top: 8px;
  cursor: pointer;
  top: -150px;
}

#howToButton:hover {
  top: 0;
}

#howToButton {
  top: -25px;
  width: 99px;
  height: 86px;
  background-size: auto;
  cursor: pointer;
  position: relative;
  background-repeat-y: no-repeat;
  background-repeat: no-repeat;
  background-image: url(assets/bandera.png);
  transition: top 0.2s;
  margin-right: 20px;
}

@media (min-width: 1280px) {
  #howToButton {
    margin-right: 25px;
  }
}

#howToButton:hover>div {
  top: 30px;
}

#game2>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#howToButton>div {
  font-family: 'Titillium Web';
  color: white;
  position: absolute;
  top: 42px;
  text-align: center;
  width: 100%;
  transition: top 0.2s;
}

#rankingButton {
  width: 119px;
  height: 148px;
  position: absolute;
  right: calc(15% - 60px);
  /*top: -24px;*/
  /*background-image: url(./assets/background/ranking.gif);*/
  background-size: cover;
  cursor: pointer;
}

#question {
  position: absolute;
  top: 17px;
  right: -34px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  background-size: cover;
  background-image: url(./assets/question.png);
}

#question:hover>div {
  visibility: visible !important;
}

#question_info {
  width: 166px;
  height: 155px;
  background-image: url(./assets/question_info.png);
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 28px;
  visibility: hidden;
}

#question_info::after {
  /*content: 'Al reverso del TV';*/
}

#question_info {
  width: 180px;
  height: 168px;
  background-image: url(./assets/question_info.jpg);
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 28px;
  visibility: hidden;
}

#userName {
  width: 173px;
  height: 57px;
  /*background-image: url(./assets/background/single-btn.png);*/
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

#userNameMobile {
  flex: 1;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

#userNameDiv {
  width: 80%;
  text-align: center;
  color: white;
  font-weight: 700;
}

#userNameDivMobile {
  width: 90%;
  text-align: start;
  color: #673612;
  font-weight: 700;
}

#imageCenterOlvidoStep2 {
  width: 729px;
  height: 55px;
  background: url(assets/olvidoStep2.png) no-repeat;
  background-size: 100% 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#imageCenterOlvido {
  width: 994px;
  height: 78px;
  background: url(assets/olvido.png) no-repeat;
  background-size: 100% 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#imageCenterConfirm {
  width: 839px;
  height: 35px;
  background: url(assets/confirm.png) no-repeat;
  background-size: 100% 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#imgRanking {
  width: 675px;
  min-width: 675px;
  height: 54px;
  background: url(assets/home/titleRanking.png) no-repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
}

#botAtras {
  width: 89px;
  height: 53px;
  background: url(assets/botAtras.png) no-repeat;
  background-size: 100% 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0px;
  top: 100px;
  cursor: pointer;
  z-index: 2;
}

#imageCenter {
  width: 428px;
  max-width: 100%;
  height: 145px;
  background: url(assets/logo.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
}

#imageCenterHome {
  width: 428px;
  height: 295px;
  background: url(assets/home/logo_juego.png) no-repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
}

#imageCenterAwards {
  width: 518px;
  height: 400px;
  background: url(assets/logo_decorado_2.png) no-repeat;
  background-size: contain;
  background-position: center;
}


#imageRanking {
  width: 183px;
  height: 190px;
  background: url(assets/home/ranking.png) no-repeat;
  background-size: 100% 100%;
}

#imagePuntos {
  width: 313px;
  height: 34px;
  background: url(assets/home/puntos.png) no-repeat;
  background-size: 100% 100%;
  font-family: Titillium Web;
  color: white;
  padding-top: 9px;
  padding-left: 35px;
  font-size: 1.3em;
}

#imagePenalty {
  width: 390px;
  height: 206px;
  background: url(assets/home/bot_penalty.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

#labelJuegoPenalty {
  font-family: Titillium Web;
  padding-top: 15px;
  padding-left: 20px;
  color: white;
  font-size: 23px;
  text-transform: uppercase;
}

#imageCall {
  width: 777px;
  height: 67px;
  background: url(assets/home/letrero_juego.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: none;
}

@media only screen and (max-width: 1030px) {
  #imageCall {
    display: none;
  }
}

#canvas {
  top: auto !important;
}

#image21_Antes {
  width: 424px;
  height: 206px;
  background: url(assets/home/bot_21.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

#image21 {
  width: 360px;
  height: 206px;
  background: url(assets/logo20.png) no-repeat;
  background-size: 100% 100%;
  background-size: contain;
  background-position: center;
}

#containerSubir {
  width: 423px;
  height: 292px;
  background: url(assets/home/back_subir.png) no-repeat;
  background-size: 100% 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  transition: left 0.2s;
  left: -421px;
}


.containerSubirNoOculto {
  left: 0px !important;
}

.containerHomeLeft {
  position: absolute;
  left: 0;
}

.containerHomeRight {
  position: absolute;
  right: 0;
}

#imageBienvenidos {
  width: 153px;
  height: 39px;
  background: url(assets/home/bienvenido.png) no-repeat;
  background-size: 100% 100%;
}

#imageRegister {
  width: 743px;
  height: 65px;
  background: url(assets/textRegister.png) no-repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

#decoScore {
  width: 214px;
  height: 17px;
  background: url(assets/dots.png) no-repeat;
  background-size: 100% 100%;
}

#loaderDiv {
  top: 0;
  bottom: 0;
  position: fixed;
  background-color: #00000078;
  right: 0;
  left: 0;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

canvas {
  margin: auto;
  display: block;
  position: relative;
}

#canvas2 {
  position: fixed;
  width: 100%;
  max-height: 100%;
  left: 0;
  /*object-fit: contain;*/
}

#timeRemained {
  padding: 6px;
  margin-top: 5px;
  background-color: #dc1961;
  color: white;
}

#timeRemainedMobile {
  padding: 6px;
  background-color: #dc1961;
  color: white;
  margin-left: auto;
  align-self: center;
}

#buttonRegContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*cursor: pointer;*/
}

#contentAppError {
  width: 80%;
  padding: 1vh 2vh;
  color: white;
  text-align: center;
  white-space: pre-wrap;
  font-family: LGSmartRegular;
  display: flex;
  align-items: center;
  justify-content: center;
}



#brownBack2AppError {
  width: 451px;
  height: 253px;
  background: url(assets/popup.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

#divHomeButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 206px;
  position: absolute;
  width: 100%;
  bottom: 25px;
}

#botCloseAppError {
  width: 35px;
  height: 35px;
  background: url(assets/closePopup.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
}

#botCloseAppError:hover {
  filter: brightness(0.85);
}

#botCloseAppError:active {
  filter: brightness(0.7);
}

#popupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 451px;
  outline: none;
  position: relative;
}

#containerMainHomeUp {
  height: 209px;
}

#divPopUpFinal {
  width: 885px;
  height: 484px;
  background-size: contain;
  position: relative;
  max-width: 90%;
}

#divMecanica {
  width: 885px;
  height: 484px;
  background: url(assets/mecanica.png) no-repeat;
  background-size: contain;
  position: relative;
  max-width: 90%;
}

#divMecanicaClose {
  width: 29px;
  height: 25px;
  background: url(assets/closePopup.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

@media only screen and (max-width: 820px) {
  #divHomeButtons {
    /*position: initial;*/
    padding-top: 15px;
    /*display: block;*/
  }

  #imagePenalty {
    /*position: inherit;*/
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 650px) {
  #divHomeButtons {
    position: initial;
    padding-top: 15px;
    display: block;
  }

  #imagePenalty {
    position: inherit;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 580px) {
  #howToButton {
    display: none;
  }

  #buttonLogin {
    position: inherit;
  }

  #menu {
    display: inherit;
  }

  #imageCenter {
    margin-top: 103px;
    min-height: 145px;
  }
}

@media only screen and (max-width: 980px) {
  #imgRanking {
    display: none;
  }
}

@media (max-width: 800px) {
  #footer {
    height: auto;
    flex-direction: column;
    /* position: relative; */
  }

  #footer_info {
    flex-wrap: wrap;
  }

  .dot {
    display: none;
  }

  #imagePenalty {
    width: 190px;
    height: 98px;
    margin-top: 60px;
  }

  .containerHomeRight {

    top: -50px;
  }


}


@media only screen and (max-width: 700px) {
  #formRegistro {
    flex-direction: column;
    align-items: center;
  }

  #formRegistro {
    flex-direction: column;
    align-items: center;
  }
  #formRegistro>div{
    margin-right: 0!important;
  }

}

@media only screen and (max-width: 530px) {
  #containerMainHomeUp {
    height: 385px
  }

  #homeLeft {
    position: relative;
  }
}

@media all and (-ms-high-contrast:none) {

  *::-ms-backdrop,
  #game1div {
    position: initial
  }

  /* IE11 */
  *::-ms-backdrop,
  #divHomeButtons {
    bottom: 140px
  }

  /* IE11 */
}

@media only screen and (max-width: 920px) {
  #decoBackTopRight {
    /*visibility: hidden;*/
  }

  #decoBackTopLeft {
    /*visibility: hidden;*/
  }

  #lateralButtonsRight {
    /*right: -160px;*/
  }

  #lateralButtonsLeft {
    /*left: -160px;*/
  }

  #decoBackTopRight {
    visibility: hidden;
  }

  #decoBackTopLeft {
    visibility: hidden;
  }

  #lateralButtonsRight {
    /*right: -188px;*/
  }

  #lateralButtonsLeft {
    /*left: -188px;*/
  }

  /*#homeLeft{
    position: relative;
  }

  #homeRight{
    position: relative;
    display: flex;
    justify-content: flex-end;
  }*/

  #imageCenterHome {
    display: none;
  }

  /*#imagePenalty{
    position: relative;
    margin: 17px 0px;
    align-self: flex-start;
  }

  #image21{
    position: relative;
    align-self: flex-end;   
  }*/

  /*#divHomeButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }*/

  /*#screenContainerHome {
    align-items: flex-start;
    padding-top: 100px;
  }*/

  /*body {
    background: url("assets/background.jpg") no-repeat center center fixed;
    background-size: auto;    
    margin: 0;    
    overflow-x: hidden;
  }*/
}