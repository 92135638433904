fieldset {
  border-color: #f8e926 !important;
}

#labelAcepto {
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9em;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

a {
  margin-left: 5px;
  color: #00b7ff;
  cursor: pointer;
  overflow: visible;
}