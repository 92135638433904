#brownBackCont {
  width: 200px;
  height: 50px;  
}

#brownBackContInt {
  width: 200px;
  height: 50px;
  overflow: hidden;
  position: relative;
  top: 3px;
}


#brownBack {
  width: calc( 100% - 6px );
  height: 200%;
  background-color: #733027;
  position: relative;
  z-index: 1001;
  border: 3px solid #7a423b;
  border-radius: 50%;
  border-bottom: none;  
} 

#ornamentsAppError {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: 3px solid #E21A67;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 3px #e21a67, 0px 0px 10px 3px #e21a67;
  z-index: -1;
}

#buttonCloseAppError {
  width: 86px;
  height: 85px;
  position: absolute;
  top: -90px;
  right: 15%;
  /*background: url(./assets/background/close-btn.png) no-repeat;*/
  background-size: 100% 100%;  
  cursor: pointer;
}

#buttonCloseAppError:hover {
  filter: brightness(0.85);  
}

#buttonCloseAppError:active {
  filter: brightness(0.7); 
}



@media only screen and (max-width: 720px) {

  .logoClass {
    width: 75px;
    height: 66px;
    top: 55px;
  }

  #brownBackContInt {
    display: none;
  }

  #buttonCloseAppError {
    position: relative;
    top: 0px;
    left: 0px;
  }

  #popContainer {
    position: relative;
    top: -25px;      
  }

  #brownBack2AppError {
    width: 90%;    
  }
}